<template>
  <loading v-if="pageLoading" :loading="pageLoading" />
  <container v-else>
    <page-header>
      <grid>
        <grid-column twelve>
          <h1>
            <editable-text
              :value="group.name"
              :disabled="isEditDisabled"
              :loading="patchingField === 'name'"
              placeholder="Add Name"
              @update="(value) => patchGroup('name', value)"
            />
          </h1>
          <editable-text
            :value="group.description"
            :disabled="isEditDisabled"
            :loading="patchingField === 'description'"
            placeholder="Add Description"
            @update="(value) => patchGroup('description', value)"
          />
        </grid-column>
        <grid-column four right-aligned>
          <g-label
            v-if="group.isSmartGroup"
            v-tippy
            content="People are automatically added and removed"
            class="smart-group-label"
            light-green
          >
            ⚡️ Smart Group
          </g-label>
          <btn
            v-if="!isEditDisabled"
            variant="secondary"
            class="overflow"
            icon="trash icon"
            tooltip="Delete Group"
            @click.native="$refs.confirm.show()"
          />
        </grid-column>
      </grid>
    </page-header>

    <dropdown
      v-if="!isEditDisabled"
      class="add-people-dropdown"
      name="people"
      button
      default-text="+ Add People"
      hide-caret
      scrolling
      hide-selection
      button-variant="primary"
      :loading="addingUser"
      @update="addUser"
      @search="getUsers"
    >
      <dropdown-search />
      <dropdown-item v-for="user in users" :key="user.id" :value="user.id">
        <g-image
          avatar
          :src="user.avatar || defaultUserAvatar"
          alt="Profile picture"
        />
        {{ user.fullName }}
      </dropdown-item>
    </dropdown>

    <list middle-aligned divided selection size="large">
      <list-item
        v-for="(edge, index) in groupEdges"
        :key="edge.id"
        :src="edge.user.avatar || defaultUserAvatar"
        :to="{ name: 'profile', params: { id: edge.user.id } }"
      >
        <list-content>
          {{ edge.user.fullName }}
        </list-content>

        <list-content float-right>
          <btn
            v-if="!isEditDisabled"
            tooltip="Remove"
            dismiss
            compact
            :loading="userLoadingList[index]"
            @click.native.prevent="() => removeUser(edge.id, index)"
          />
        </list-content>
      </list-item>

      <placeholder
        v-if="groupEdges.length === 0"
        vertically-aligned
        centered
        light
      >
        {{ placeholderText }}
      </placeholder>
    </list>

    <pagination
      v-if="groupEdgesPagination && groupEdgesPagination.lastPage > 1"
      :num-of-pages="groupEdgesPagination.lastPage"
    />

    <confirm-modal
      ref="confirm"
      negative
      approve-text="Delete Group"
      deny-text="Cancel"
      size="mini"
      @approve="deleteGroup"
    >
      Are you sure you want to delete the {{ group.name }} group?
    </confirm-modal>
  </container>
</template>

<script>
import { api } from '@/api'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import btn from '@/components/v2/btn.vue'
import editableText from '@/components/v2/editable_text.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import pageHeader from '@/components/v2/page_header.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import gLabel from '@/components/v2/label.vue'
import gImage from '@/components/v2/image.vue'

export default {
  components: {
    list,
    listItem,
    listContent,
    loading,
    container,
    pagination,
    btn,
    grid,
    gridColumn,
    placeholder,
    pageHeader,
    editableText,
    dropdown,
    dropdownItem,
    dropdownSearch,
    confirmModal,
    gLabel,
    gImage,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      defaultUserAvatar,
      group: {},
      groupEdges: [],
      groupEdgesPagination: null,
      pageLoading: false,
      patchingField: null,
      users: [],
      addingUser: false,
      userLoadingList: [],
    }
  },
  computed: {
    placeholderText() {
      return 'This group has no members, you can add them using the Add Person button'
    },
    isEditDisabled() {
      return this.group.isSmartGroup
    },
  },
  watch: {
    $route: {
      handler() {
        this.getPage()
      },
    },
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.pageLoading = true
        await this.getGroup()
        await this.getGroupEdges()
        await this.getUsers()
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async getGroup() {
      try {
        this.group = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${this.id}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getGroupEdges() {
      try {
        const { data, cursor } = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${this.id}/members`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            userStatus: ['active', 'onboarding'],
            sort: 'preferredFirstName,lastName,id',
          }
        )
        this.groupEdges = data
        this.groupEdgesPagination = cursor

        this.userLoadingList = this.groupEdges.map(() => false)
      } catch (error) {
        toast.error(error)
      }
    },
    async getUsers(search) {
      try {
        this.users = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/users`, {
            excludeGroup: this.id,
            ...(search ? { search } : {}),
            status: ['active', 'onboarding'],
            sort: 'preferredFirstName,lastName,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async patchGroup(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${this.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      await this.getGroup()
    },
    async deleteGroup() {
      try {
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${this.id}`
        )
        this.$router.push({ name: 'groups' })
      } catch (error) {
        toast.error(error)
      }
    },
    async addUser(userId) {
      try {
        this.addingUser = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${this.id}/members`,
          { userId }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.addingUser = false
      }

      await this.getGroupEdges()
      await this.getUsers()
    },
    async removeUser(userGroupRoleId, index) {
      try {
        this.userLoadingList = this.userLoadingList.map(
          (_user, i) => i === index
        )
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${this.id}/members/${userGroupRoleId}`
        )
      } catch (error) {
        toast.error(error)
      }

      await this.getGroupEdges()
      await this.getUsers()
    },
  },
}
</script>

<style lang="less" scoped>
.overflow {
  margin-top: 1em;
  margin-left: 1em;
}

.smart-group-label {
  margin-top: 1.5em;
}

.add-people-dropdown {
  margin-bottom: 1em;
}
</style>
